










































































































































































import {CouponsSet} from "@/request/marketing/CouponsSet";
import {MemberSet} from "@/request/customer/MemberSet";

export default {
    data() {
        return {
            tabIndex: 0,
            loading: false,
            params: {
                bvalid: true,
                pageIndex: 1,
                pageSize: 2
            },
            formData: {
                name: '',
                couponFee: 1,
                fullFee: 1,
                grantNum: 0,
                postNum: 0,
                perNum: 1,
                getStartTime: '',
                getEndTime: '',
                validStartTime: ''
            },
            formRules: {
                name: {required: true, message: '请填写名称', trigger: 'blur'},
                grantNum: [
                    {type: 'number', required: true, message: '数量不能为空', trigger: 'blur' }
                ],
                perNum: {type: 'number', required: true, message: '数量不能为空', trigger: 'blur' },
                getStartTime: {required: true, message: '领券时间不能为空', trigger: 'blur' },
                validStartTime: {required: true, message: '有效时间不能为空', trigger: 'blur' },
            },
            list: {},
            lvs: [],
            showDrawer: false
        }
    },
    created() {
        let self: any = this;
        self.getData();
    },
    watch: {
        tabIndex() {
            let self: any = this;
            self.getData();
        }
    },
    methods: {
        getData() {
            let self: any = this;
            self.params.bvalid = self.tabIndex===0;
            const msg = self.$Message.loading({
                content: '加载中...',
                duration: 0
            })
            CouponsSet.list(self.params).then((body: any) => {
                setTimeout(msg, 500);
                self.list = body.data;
            })
        },
        createNew() {
            let self: any = this;
            self.$refs['el-form'].resetFields();
            self.showDrawer = true;
            MemberSet.getAll().then((body: any) => {
                self.lvs = body.data;
            })
        },
        submit() {
            let self: any = this;
            self.$refs['el-form'].validate((val: boolean) => {
                if (val) {
                    self.loading = true;
                    CouponsSet.save(self.formData).then((body: any) => {
                        self.loading = false;
                        if (body.code === 0) {
                            self.getData();
                            self.$Message.info('成功');
                            self.showDrawer = false;
                        }
                    })
                }
            })
        },
        pageChange(pageIndex: number) {
            let self: any = this;
            self.params.pageIndex = pageIndex;
            self.getData();
        },
        del(id: string) {
            let self: any = this;
            CouponsSet.del([id]).then((body: any) => {
                if (body.code === 0) {
                    self.getData();
                }
            })
        }
    }
}
